import { Address } from '@caloptima/portal-foundation';
import { ProviderType } from './provider-type';
import { CollectionType } from './collection-type';
import { AttestationStatus } from './attestationstatus';
import { TrainingAttestationStatus } from './training-attestation-status';

export class ProviderDetail {
  public providerID: string;
  public providerNPI: string;
  public associationID?: number;
  public calProviderID?: string;
  public collectionID?: number;
  public providerType: ProviderType;
  public providerAddressType: string;
  public collectionType?: CollectionType;
  public collectionTypeKeyValue: string;
  public collectionName: string;
  public taxId?: string;
  public groupId?: string;
  public groupName: string;
  public taxName: string;
  public name: string;
  public effectiveDate?: string;
  public terminationDate?: string;
  public address: Address;
  public addressFull: string;
  public phoneNumber: string;
  public fax?: string;
  public email: string;
  public pcpMemberCount: number;
  public bhiPrescriberNPI: string;
  public bhiPrescriberTaxID: string;
}

export class PcpInfo {
  pcpId: string;
  pcpName: string;
}


export class ProviderDetailWithAttestation extends ProviderDetail {
    public attestationStatus : AttestationStatus;
}

export class ProviderDetailsWithTrainingAttestation extends ProviderDetail {
    public trainingAttestationStatus: TrainingAttestationStatus;
    public trainingAttestationStatusDisplay: string;
    public trainingDueDate?: number;
    public attestationDate?: Date;
    public attendees: string;
    public trainingCompleted: string;
}

export class ProviderDetailsExtend extends ProviderDetail {
  public selected: boolean = false;
  public disabled: boolean = false;

  public copyFrom(source: ProviderDetail) {
    this.providerID = source.providerID;
    this.providerNPI = source.providerNPI;
    this.associationID = source.associationID;
    this.calProviderID = source.calProviderID;
    this.collectionID = source.collectionID;
    this.providerType = source.providerType;
    this.providerAddressType = source.providerAddressType;
    this.collectionType = source.collectionType;
    this.collectionTypeKeyValue = source.collectionTypeKeyValue;
    this.collectionName = source.collectionName;
    this.taxId = source.taxId;
    this.groupId = source.groupId;
    this.groupName = source.groupName;
    this.taxName = source.taxName;
    this.name = source.name;
    this.effectiveDate = source.effectiveDate;
    this.terminationDate = source.terminationDate;
    this.address = source.address;
    this.addressFull = source.addressFull;
    this.phoneNumber = source.phoneNumber;
    this.fax = source.fax;
    this.email = source.email;
    this.pcpMemberCount = source.pcpMemberCount;
  }
}

export class ProviderDetailWithBHI {
  public providerID: string;
  public providerNPI: string;
  public associationID?: number;
  public calProviderID?: string;
  public collectionID?: number;
  public providerType: ProviderType;
  public providerAddressType: string;
  public collectionType?: CollectionType;
  public collectionTypeKeyValue: string;
  public collectionName: string;
  public taxId?: string;
  public groupId?: string;
  public groupName: string;
  public taxName: string;
  public name: string;
  public effectiveDate?: string;
  public terminationDate?: string;
  public address: Address;
  public addressFull: string;
  public phoneNumber: string;
  public fax?: string;
  public email: string;
  public pcpMemberCount: number;  
  public bhiPrescriberList: PresriberDetail[] = [];
  public bhiPrescriberNPI: string;
  public bhiPrescriberTaxID: string;
}

export class PresriberDetail
{
    public prescriberTaxID: string;
    public prescriberNPI: string;
}
